<template>
  <div>
    <div class="columns">
      <div class="column">
        <h2 class="title is-3">Статистика: {{ getCategoryText() }}</h2>
      </div>
    </div>

    <b-collapse
      class="card mb-7"
      animation="slide"
      aria-id="contentIdForA11y3"
      :open="false">
      <template #trigger="props">
        <div
          class="card-header"
          role="button"
          aria-controls="contentIdForA11y3"
          :aria-expanded="props.open">
          <p class="card-header-title">
            Фильтры
          </p>
          <a class="card-header-icon">
            <b-icon
              :icon="props.open ? 'menu-up' : 'menu-down'">
            </b-icon>
          </a>
        </div>
      </template>

      <div class="card-content">
        <div class="content">
          <div class="columns">
            <div class="column is-6">
              <b-field label="Период от">
                <b-datepicker
                  :first-day-of-week="1"
                  v-model="filters.period_from"
                  ref="datepicker"
                  expanded
                  placeholder="От">
                </b-datepicker>
                <b-button
                  @click="$refs.datepicker.toggle()"
                  icon-left="calendar-today"
                  type="is-primary" />
              </b-field>

              <div class="label">
                Количество кликов
                <b-tooltip multilined type="is-info is-light"
                           label="В таблицу будут загружены только офферы с количеством кликов
                           больше указанного (например 300) за выбранный вами период.
                           Так вы можете убрать из зоны видимости нецелевые, непопулярные офферы"
                           position="is-right">
                  <b-icon icon="help-circle-outline" size="is-small"></b-icon>
                </b-tooltip>
              </div>

              <b-field>
                <b-input
                  type="number"
                  placeholder="Количество кликов"
                  v-model="filters.clicks">
                </b-input>
              </b-field>
            </div>

            <div class="column is-6">
              <b-field label="Период до">
                <b-datepicker
                  :first-day-of-week="1"
                  v-model="filters.period_to"
                  ref="datepicker2"
                  expanded
                  placeholder="До">
                </b-datepicker>
                <b-button
                  @click="$refs.datepicker2.toggle()"
                  icon-left="calendar-today"
                  type="is-primary" />
              </b-field>

              <b-field label="Гео">
                <v-select
                  outlined
                  v-model="filters.countries"
                  :items="available_countries"
                  item-value="code"
                  item-text="name"
                  multiple
                ></v-select>
              </b-field>

            </div>
          </div>

        </div>
      </div>
      <footer class="card-footer pa-2 is-justify-content-flex-end">
        <b-button @click="clearFilters" class="mr-4">Очистить</b-button>
        <b-button @click="getStatisticData" type="is-primary">Загрузить</b-button>
      </footer>
    </b-collapse>

    <div class="columns">
      <div class="column">
        <h2 class="title is-3">Строк ({{ stat_data.total ? stat_data.total : 0 }})</h2>
      </div>
    </div>

    <!-- ТАБЛИЦА -->
    <b-table
      :data="stat_data.rows"
      default-sort="clicks"
      backend-sorting
      @sort="onSort"
      default-sort-direction="desc"
      class="users-table">

      <b-table-column label="Оффер" v-slot="props">
        <span>{{props.row.offer}} ({{props.row.offer_id}})</span>
      </b-table-column>

      <b-table-column label="Партнёрская сеть" v-slot="props">
        <span>{{props.row.affiliate_network}}</span>
      </b-table-column>

      <b-table-column
        label="Клики"
        v-slot="props"
        field="clicks"
        sortable
        numeric>
        <span>{{props.row.clicks}}</span>
      </b-table-column>

      <b-table-column
        label="EPC"
        v-slot="props"
        field="epc_confirmed"
        sortable
        numeric>
        <span>{{ Number(props.row.epc_confirmed).toFixed(2) }}</span>
      </b-table-column>

      <b-table-column v-if="filters.countries.length > 1" label="Страны" v-slot="props">
        <span>{{props.row.country_code}}</span>
      </b-table-column>
    </b-table>

    <OverlayLoader :show="isLoading"></OverlayLoader>

  </div>
</template>

<script>
import OverlayLoader from "@/app/shared/components/app-loader/Loader";

export default {
  name: "StatisticsList",
  components: {
    OverlayLoader
  },
  watch: {
    category(newCategory, oldCategory) {
      if (newCategory !== oldCategory) {
        this.$store.commit('SET_STATISTICS', {})
      }
    }
  },
  data() {
    return {
      filters: {
        period_from: new Date(),
        period_to: new Date(),

        clicks: 300,
        countries: ['RU'],

        sortBy: 'clicks',
        sortOrder: 'desc'
      },

      available_countries: [
        { name: 'Россия', code: 'RU' },
        { name: 'Казахстан', code: 'KZ' },
        { name: 'Узбекистан', code: 'UZ' },
        { name: 'США', code: 'US' },
      ],

      categories:  {
        total: "общая",
        aso: "ASO",
        buying: "баинг",
        buying_mmm: "баинг МММ",
        buying_serj: "баинг Серёжа",
      }
    };
  },
  props: {
    open: false,
  },
  created() {
  },
  computed: {
    category() {
      return this.$route.params.category;
    },
    isLoading: {
      get() {
        return this.$store.getters.IS_STATISTICS_LOADING;
      },
      set(value) {
        this.$store.commit("SET_STATISTICS_LOADING", value);
      },
    },
    stat_data: {
      get() {
        return this.$store.getters.GET_STATISTICS;
      },
      set(value) {
        this.$store.commit("SET_STATISTICS", value);
      },
    }
  },
  methods: {
    onSort(field, order) {
      this.filters.sortBy = field;
      this.filters.sortOrder = order;
      this.getStatisticData(); // Перезагружаем данные с новыми параметрами
    },
    getStatisticData() {
      let body = Object.assign({}, this.filters)

      body.period_from = this.isValidDate(body.period_from)
        ? this.formatDate(body.period_from)
        : null;

      body.period_to = this.isValidDate(body.period_to)
        ? this.formatDate(body.period_to)
        : null;

      body.type = this.category;

      this.$store.dispatch("GET_STATISTICS", body)
        .then(() => {
          this.$toast.success("Статистика получена");
        })
        .catch(() => {
        this.$toast.error("Ошибка при получении статистики");
      });
    },

    formatDate(date) {
      return date.toLocaleDateString('ru-RU').split('.').reverse().join('-');
    },
    isValidDate(date) {
      return date instanceof Date && !isNaN(date.getTime());
    },
    clearFilters() {
      this.filters = {
        period_from: new Date(),
        period_to: new Date(),

        clicks: 300,
        countries: ['RU'],
      };
    },
    getCategoryText() {
      return this.categories[this.category] || "ошибка";
    }
  },
};
</script>
